import {
    LEGAL_BASES_PLACEHOLDER,
    NOTARY_PARAGRAPH,
    ORGANIZATION_PARAGRAPH,
    LOTTERY_PARAGRAPH
} from 'constants/legal-bases-placeholder.js';
import MDtoPDF from 'utils/md-to-pdf.js'
import { storage } from '../../firebase-info.js';


function _paragraphSelector(data, type){
    const paragraphs = {
        internal: ORGANIZATION_PARAGRAPH,
        notary: NOTARY_PARAGRAPH,
        lottery: LOTTERY_PARAGRAPH,
    };

    return paragraphs[type](data);
}

export async function generateLegalBases(data) {
    //const doc = new jsPDF();
    const {
        projectId,
        cif,
        type,
    } = data;
    
    data.winnerSelection = _paragraphSelector(data, type);

    const content = LEGAL_BASES_PLACEHOLDER(data);
    const pdfFromMarkdown = MDtoPDF()
    const doc = pdfFromMarkdown(content).parseMarkdown().getDoc();
    const blob = doc.output('blob');

    const storageRef = storage.ref();
    const fileRef = storageRef.child(`documents/orgDocuments/${cif}/projects/${projectId}/legalBases.pdf`);
    try{
        const ref = await fileRef.put(blob);
        const docUrl = `gs://${ref.metadata.bucket}/${ref.metadata.fullPath}`;
        const docDownloadUrl = await storage.refFromURL(docUrl).getDownloadURL();
        return docDownloadUrl;
    }
    catch(err){
        console.log(err);
    }

}