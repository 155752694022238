import * as React from 'react';
import { navigate, Link } from "gatsby"
import { 
	Box,
	IconButton,
	Grid,
	Typography,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import LogoutIcon from '@mui/icons-material/Logout';
import PapFooter from 'components/pap-footer.js';
import PapCreateProjectHipatia from 'components/pap-create-project-hipatia.js';
import PapCreateProjectPitagoras from 'components/pap-create-project-pitagoras.js';
import PapCreateProjectAnaxagoras from 'components/pap-create-project-anaxagoras.js';
import PapCreateProjectSocrates from 'components/pap-create-project-socrates.js';
import PapCreateProjectAristoteles from 'components/pap-create-project-aristoteles.js';
import LogoDowinWhite from "images/logodowinWhite.png";
import { auth } from '../../../../firebase-info';

// styles
const pageStyles = {
	fontFamily: 'Quicksand',
};
const topBar = {
	height: '56px',
	margin: '16px',
	marginTop: '0px',
}
const imageContainer = {
	height: '36.7px',
}
const headerMargin = {
	marginBottom: '20px',
	marginLeft: '16px',
	display: 'inline-block',
	verticalAlign: 'middle',
}
const logout = {
	marginBottom: '20px',
	marginLeft: 'auto',
	verticalAlign: 'middle',
}
const verticalPadding40 = {
	paddingTop: '40px',
	paddingBottom: '40px',
}
const heavyBlue = {
	backgroundColor: '#2C90CB',
}
const headerLink = {
	textDecoration: 'none',
	color: '#FFF',
	fontWeight: 500,
}

const NOTARY = 'hipatia';
const LOTTERY = 'pitagoras';
const INTERNAL = 'anaxagoras';
const PROMOTION = 'socrates';
const DOWIN = 'aristoteles';
// markup
const CreateProject = (props) => {
	const [loading, setLoading] = React.useState(true)
    const { type } = props;
	const philosophers = {
        anaxagoras: 'Anaxágoras',
        aristoteles: 'Aristóteles',
        hipatia: 'Hipatia',
        pitagoras: 'Pitágoras',
        socrates: 'Sócrates',
    };


	function checkLogin() {
		auth.onAuthStateChanged(function(user) {
			if(!user){
				navigate("/solicitants");
				return;
			}
			setLoading(false);
		  });
	};

	function _getType(projectType) {
		const typeEnum = {
			anaxagoras: 'internal',
			aristoteles: 'rewardDowin',
			hipatia: 'notary',
			pitagoras: 'lottery',
			socrates: 'reward',
		}

		return typeEnum[projectType];
	}
	
	React.useEffect(() => {
		checkLogin();
	}, []);

	function performLogout() {
		auth.signOut();
		navigate("/");
	}

	const theme = createTheme(
		{
			components: {
				MuiInputBase: {
					styleOverrides: {
						root: {
							fontFamily: 'Quicksand !important',
							color: '#000000',
						}
					}
				},
				MuiToolbar: {
					styleOverrides: {
						root: {
							fontFamily: 'Quicksand !important',
							color: '#FFFFFF',
						},
					},
				},
				MuiInputLabel: {
					styleOverrides: {
						root: {
							fontFamily: 'Quicksand !important',
						},
					},
				},
			},
		},
	);

	return (
		<main style={{backgroundColor: loading ? '#FFF' : '#000', height: '100%'}}>
			{loading ? 
				<></>
				:
				<>
					<Box style={pageStyles}>
						<Box style={heavyBlue}>
							<Grid container spacing={2} style={topBar}>
								<Grid item xs={11}>
									<Link to="/">
										<img
											alt="dowin Logo"
											style={imageContainer}
											src={LogoDowinWhite}
										/>
									</Link>
									<Box style={headerMargin}>
										<Typography fontFamily={"Quicksand"} color={"#FFFFFF"} display={"inline"}> Organizaciones y solicitantes </Typography>
									</Box>
									<Box style={headerMargin}>
										<Link  style={headerLink} fontFamily={"Quicksand"} color={"#FFFFFF"} display={"inline"} to={"/dashboard"}> Mis proyectos </Link>
									</Box>
									<Box style={headerMargin}>
										<Link style={headerLink} fontFamily={"Quicksand"} color={"#FFFFFF"} display={"inline"} to={"/project/select-project"}> Tipos de proyectos </Link>
									</Box>
									<Box style={headerMargin}>
										<Link style={headerLink} fontFamily={"Quicksand"} color={"#FFFFFF"} display={"inline"} to={"/profile"}> Mi perfil </Link>
									</Box>
								</Grid>
								<Grid item xs={1}>
									<IconButton style={logout} aria-label="Cerrar sesión" size="large">
										<LogoutIcon onClick={performLogout} fontSize="inherit" style={{fill: '#FFFFFF'}}/>
									</IconButton>
								</Grid>
							</Grid>
							<Grid style={verticalPadding40} justifyContent={"center"} container spacing={2}>
								<Grid item xs={10}>
									<Typography fontFamily={'Quicksand'} fontWeight={700} fontSize={28} pb={"24px"} color={'#FFF'}>Introduce los datos para crear tu proyecto {philosophers[type]}:</Typography>
									<ThemeProvider theme={theme}>
										{type === NOTARY && <PapCreateProjectHipatia type={_getType(type)}/>}
										{type === LOTTERY && <PapCreateProjectPitagoras type={_getType(type)}/>}
										{type === INTERNAL && <PapCreateProjectAnaxagoras type={_getType(type)}/>}
										{type === PROMOTION && <PapCreateProjectSocrates type={_getType(type)}/>}
										{type === DOWIN && <PapCreateProjectAristoteles type={_getType(type)}/>}
									</ThemeProvider>
								</Grid>
							</Grid>
						</Box>
					</Box>
					<PapFooter/>
				</>
			}
		</main>
	);
};

export default CreateProject;
