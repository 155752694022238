import React from 'react';
import {
		Alert,
		Backdrop,
		Button,
		CircularProgress,
		Grid,
		Input,
		InputLabel,
		FormControlLabel,
		Checkbox,
		Paper,
		Step,
		Stepper,
		StepLabel,
		Typography,
} from '@mui/material';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { auth, db, storage, firestore } from '../../firebase-info';
import { navigate } from 'gatsby';
import { generateLegalBases } from '../utils/legal-bases';
import PapProjectDetailInApp from 'components/pap-project-detail-inapp';
import MDEditor from '@uiw/react-md-editor';

const mkdStr = `### Aquí tienes un ejemplo de descripción. ¡Puedes editarla como quieras!
### Intenta que sea lo más atractiva posible para los donantes 😊
#### Haz tus campañas más atractivas con [Markdown](https://es.wikipedia.org/wiki/Markdown)!

Markdown te permite hacer esta descripción más visual de manera sencilla.

Aquí está el ejemplo:

----

Este es un proyecto que realizaremos entre febrero y mayo de 2022 en Cusco (Perú). Buscamos seguir ayudando a los *Niños/as, Adolescentes y Jóvenes en Situación de Calle*, ahora, en conflicto con la justicia. 

Los altos niveles de **adicción, malos tratos, abusos sexuales o los problemas de identidad** serán nuestro objetivo de abordaje tanto a nivel psicoterapéutico como formativo. Para tal labor, **es ahora cuando os necesitamos**. Con esta acción también buscamos beneficiar a los técnicos nacionales de otros centros de reforma del país. Todo mientras ayudamos a los menores de Cusco, una vez salgan de Marcavalle, a seguir con su proceso desde la *Comunidad Terapéutica Virgen del Rosario*, entidad con la que colaboraremos en su mejora y que funcionará como contraparte de esta acción. 

📢 [**Quienes somos Voces Para LatinoAmérica**](https://web.vocespara.info/quien-es-voces/carta-presentacion-vocesparalatinoamerica)

🎯 [**Detalle del proyecto**](https://web.vocespara.info/wp-content/uploads/2017/07/0_FINAL_ok_fotos_ayuda_si_te_quieres_ayudar_2022.pdf)

---

Colaboran con este proyecto:

▶ [Seed Drill capital](https://seeddrillcapital.com/)
`;
const MKDPRIZE = `### Introduce aquí la descripción de tu premio. Déjala vacía para usar una por defecto.`;
const MKDWINNER = `### Introduce aquí la descripción de cómo se elegirá ganador/a. Déjala vacía para usar una por defecto.`;

const inputStyle = {
	width: '100%',
	backgroundColor: '#FFFFFF',
	borderRadius: '4px',
	borderWidth: '0px',
	fontFamily: 'Quicksand',
	fontSize: '24px',
	lineHeight: '24px',
		paddingLeft: '0px',
	paddingRight: '0px',
	marginTop: '16px',
}
const inputStyleLabel = {
	width: '100%',
	backgroundColor: '#FFFFFF',
	borderRadius: '4px',
	borderWidth: '0px',
	fontFamily: 'Quicksand',
	fontSize: '24px',
	lineHeight: '24px',
		paddingLeft: '0px',
	paddingRight: '0px',
	marginBottom: '16px',
}
const stepLabelStyle = {
	fontFamily: 'Quicksand',
	fontSize: '18px',
	lineHeight: '24px',
}

export default class PapCreateProjectHipatia extends React.Component {

	state = {
		open: false,
		title: '',
		description: mkdStr,
		orgDescription: '',
		goalAmount: '',
		pic: '',
		startDate: '',
		endDate: '',
		drawDate: '',
		price: '',
		prize: '',
		type: this.props.type,
		isPrivate: false,
		privatePass: '',
		notaryName: '',
		winningCondition: MKDWINNER,
		prizeDescription: MKDPRIZE,
		orgRef: null,
		orgSnapshot: null,
		activeStep: 0,
	};

	async componentDidMount() {
		const userRef = await db.collection(`users`).doc(auth.currentUser.uid);
		const userSnapshot = await userRef.get();
		const orgRef = userSnapshot.get('orgRef');
		const orgSnapshot = await orgRef.get();

		this.setState({ ...this.state, orgRef });
		this.setState({ ...this.state, orgSnapshot });

		ValidatorForm.addValidationRule('isPastDate', (value) => {
				if (new Date(value) < new Date()) {
						return false;
				}
				return true;
		});

		ValidatorForm.addValidationRule('oneWeekDate', (value) => {
			const today = new Date();
			const nextweek = new Date(today.getFullYear(), today.getMonth(), today.getDate()+7);

			if (new Date(value) < nextweek) {
				return false;
			}

			return true;
		});


		ValidatorForm.addValidationRule('dateIsSmallerThanStartDate', (value) => {
			if (!this.state.startDate || new Date(value) <= new Date(this.state.startDate)) {
				return false;
			}
			return true;
		});

		ValidatorForm.addValidationRule('dateIsSmallerThanEndDate', (value) => {
			if (!this.state.endDate || new Date(value) <= new Date(this.state.endDate)) {
				return false;
			}
			return true;
		});
}

	componentWillUnmount() {
			ValidatorForm.removeValidationRule('isPastDate');
			ValidatorForm.removeValidationRule('oneWeekDate');
			ValidatorForm.removeValidationRule('dateIsSmallerThanStartDate');
			ValidatorForm.removeValidationRule('dateIsSmallerThanEndDate');
	}

	handleChange = (prop) => (event) => {
		this.setState({ ...this.state, [prop]: event.target.value });
	};

	handleMarkdownChange = (prop) => (event) => {
		this.setState({ ...this.state, [prop]: event });
	};

	handleCheckChange  = (event) => {
		this.setState({ ...this.state, [event.target.name]: event.target.checked });
	};

	handleNextStep = () => {
		this.setState({ ...this.state, activeStep: this.state.activeStep + 1 });
	};

	handleBack = () => {
		this.setState({ ...this.state, activeStep: this.state.activeStep - 1 });
	};

	handleReset = () => {
		this.setState({ ...this.state, activeStep: 0 });
	};

	readURI(e){
		if(e.target.files && e.target.files[0]){
			let reader = new FileReader();
			reader.onload = function(ev){
				this.setState({imgPreviewURL:ev.target.result});
			}.bind(this);
		  	reader.readAsDataURL(e.target.files[0]);
		}
	}

	handleFileChange = (prop) => (event) => {
		this.setState({ ...this.state, [prop]: event.target.files, });
		this.readURI(event);
	}

	formatDate(date = new Date(), isHuman = false) {
		const fDate = new Date(date);
		try {
			const dd = String(fDate.getDate()).padStart(2, '0');
			const mm = String(fDate.getMonth() + 1).padStart(2, '0');
			const yyyy = fDate.getFullYear();
	
			return isHuman ? `${dd}-${mm}-${yyyy}` :  `${yyyy}-${mm}-${dd}`;
		} catch {
			const dd = String(new Date().getDate()).padStart(2, '0');
			const mm = String(new Date().getMonth() + 1).padStart(2, '0');
			const yyyy = new Date().getFullYear();

			return `${dd}-${mm}-${yyyy}`;
		}
	}

	handleLoadingClose = () => {
	 this.setState({ ...this.state, open:  false });
	};
	handleLoadingToggle = () => {
		this.setState({ ...this.state, open:  !this.state.open });
	};

	handleSubmit = async () => {
		try {
			this.handleLoadingToggle();
			const orgRef = this.state.orgRef;
			const orgSnapshot = this.state.orgSnapshot;
			const cif =  orgSnapshot.get('cif');
			const imgName = this.state.pic && this.state.pic[0].name;
			const today = this.formatDate();
			const storageRef = storage.ref();
			const maxParticipations = Math.ceil(parseFloat(this.state.goalAmount) / parseFloat(this.state.price));
			let imageUrl = null;
			let imgDownloadUrl = null;

			if (imgName) {
				const name = `${cif}_${today}_${imgName}`;
				const fileRef = storageRef.child('images/drawsPromoPics/'+name);
				const snapshotImg = await fileRef.put(this.state.pic[0]);
				imageUrl = `gs://${snapshotImg.metadata.bucket}/${snapshotImg.metadata.fullPath}`;
				imgDownloadUrl = await storage.refFromURL(imageUrl).getDownloadURL();
			}

			const prizeDescription = `Con la donación que realizas en este sorteo puedes ganar el estupendo premio de **${this.state.prize}**. ¡Tu ayuda tiene premio!`;
			const winningCondition = `La persona (o varias personas) agraciada será elegida de acuerdo a las bases del sorteo más abajo listadas. Se realizará el sorteo ante el Notario del Ilustre Colegio de Notarios **${this.state.notaryName}** el día **${this.formatDate(this.state.drawDate, true)}**`;
			const drawRef = await db.collection("organizations").doc(orgRef.id).collection("draws").add({
				isVisible: true,
				title: this.state.title,
				description: this.state.description,
				winningCondition: (this.state.winningCondition === MKDWINNER || !this.state.winningCondition) ? winningCondition : this.state.winningCondition,
				prizeDescription: (this.state.prizeDescription === MKDPRIZE || !this.state.prizeDescription) ? prizeDescription : this.state.prizeDescription,
				orgDescription: this.state.orgDescription,
				prize: this.state.prize,
				imageUrl,
				imgDownloadUrl,
				maxParticipations,
				organizationId: orgRef,
				pricePerParticipation: this.state.price,
				soldParticipations: [],
				type: this.state.type,
				isPrivate: this.state.isPrivate,
				privatePass: this.state.privatePass,
				startDate: firestore.Timestamp.fromDate(new Date(this.state.startDate)),
				closingDate: firestore.Timestamp.fromDate(new Date(this.state.endDate)),
				drawDate: firestore.Timestamp.fromDate(new Date(this.state.drawDate)),
				notaryName: this.state.notaryName,
			});
			const data = {
				projectId: drawRef.id,
				orgName: orgSnapshot.get('organization'),
				title: this.state.title,
				prize: this.state.prize,
				pricePerParticipation: this.state.price,
				maxParticipations,
				location: orgSnapshot.get('location'),
				address: orgSnapshot.get('address'),
				cif,
				startDate: this.formatDate(new Date(this.state.startDate), true),
				closingDate: this.formatDate(new Date(this.state.endDate), true),
				drawDate: this.formatDate(new Date(this.state.drawDate), true),
				type: this.state.type,
				notaryName: this.state.notaryName,
			};

			const docDownloadUrl = await generateLegalBases(data);

			await drawRef.update({
				legalBasesUrl: firestore.FieldValue.arrayUnion(docDownloadUrl)
			  });

			this.handleLoadingClose();
			this.handleReset();
			navigate('/dashboard');
		} catch {
			this.setState({ ...this.state, error: true });
			this.handleReset();
			setTimeout(() => { this.setState({ ...this.state, error: false }) }, 3500);
			this.handleLoadingClose();
		} 
	}

	render() {
		const {
			activeStep,
			description,
			drawDate,
			endDate,
			error,
			goalAmount,
			isPrivate,
			notaryName,
			price,
			privatePass,
			prize,
			prizeDescription,
			startDate,
			title,
			winningCondition,
		} = this.state;

		return (
			<>
				{error && <Alert severity="error">Ha ocurrido un problema, por favor, inténtelo más tarde.</Alert> }
				<Backdrop open={this.state.open} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
					<CircularProgress color="inherit" />
				</Backdrop>
				<Grid container spacing={2}>
					<Grid item xs={9}>
						<Grid container justifyContent={"center"}>
							<Grid item xs={12}>
								<Grid container style={{backgroundColor: '#FFF', padding: '16px', borderRadius: '5px'}} spacing={2}>
									<Grid item xs={12}>
										<Stepper activeStep={activeStep} style={{paddingBottom: '16px'}}>
											<Step key={'projectInformation'}>
												<StepLabel><label style={stepLabelStyle}>Información del proyecto</label></StepLabel>
											</Step>
											<Step key={'funds'}>
												<StepLabel><label style={stepLabelStyle}>Alcance y fondos</label></StepLabel>
											</Step>
											<Step key={'reward'}>
												<StepLabel><label style={stepLabelStyle}>Recompensa</label></StepLabel>
											</Step>
											<Step key={'dates'}>
												<StepLabel><label style={stepLabelStyle}>Fechas y elegibilidad de ganadores</label></StepLabel>
											</Step>
										</Stepper>
									</Grid>
									{activeStep === 0 && <React.Fragment>
										<ValidatorForm
											ref="form1"
											style={{ width: '100%' }}
											onSubmit={this.handleNextStep}
											onError={errors => console.log(errors)}
										>
											<Grid container spacing={2}>
												<Grid item xs={12}>
													<TextValidator
															label="Nombre de la campaña de donaciones solicitada"
															onChange={this.handleChange('title')}
															name="title"
															value={title}
															style={inputStyle}
															validators={['required', 'maxStringLength:50']}
															errorMessages={['Este campo es obligatorio', 'El título no puede tener más de 50 caracteres']}
													/>
												</Grid>
												<Grid item xs={12}>
													<Paper >
														<label style={{ color: 'rgba(0, 0, 0, 1)', padding: 5}} for="prprivateCheckice">Descripción del proyecto y sorteo</label>

														<div className="container" style ={{paddingTop: 5}}>
															<MDEditor style={{fontFamily: 'Quicksand'}} height={400} value={description} onChange={this.handleMarkdownChange('description')} />
														</div>
													</Paper>
												</Grid>
												<Grid item xs={12}>
													<InputLabel sx={{ color: "#000", fontFamily: "Quicksand"}} htmlFor="draftImage">Imagen de la campaña de donaciones</InputLabel>
													<Input
														label="Imagen del proyecto"
														id='draftImage'
														onChange={this.handleFileChange('pic')}
														style={{width: '100%', marginTop: '15px', marginBottom: '15px', textTransform: 'capitalize', justifyContent: 'left', fontFamily: 'Quicksand'}}
														name="orgImage"
														type="file"
													/>
												</Grid>
												<Grid item  xs={12} justifyContent={'center'} sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
													<Button color="inherit" disabled={activeStep === 0} onClick={this.handleBack} sx={{ mr: 1 }}>
														Atrás
													</Button>
													<Button type="submit" variant="contained">
														Siguiente
													</Button>
												</Grid>
											</Grid>
										</ValidatorForm>
									</React.Fragment>}
									{activeStep === 1 && <React.Fragment>
										<ValidatorForm
											ref="form2"
											style={{ width: '100%' }}
											onSubmit={this.handleNextStep}
											onError={errors => console.log(errors)}
										>
											<Grid container spacing={2}>
												<Grid item xs={6}>
													<TextValidator
															label="Cantidad total solicitada (en euros)"
															onChange={this.handleChange('goalAmount')}
															name="goalAmount"
															type="number"
															style={inputStyle}
															value={goalAmount}
															validators={['required', 'minNumber:500']}
															errorMessages={['Este campo es obligatorio', 'La cantidad solicitada debe ser de al menos 500 €']}
													/>
												</Grid>
												<Grid item xs={6}>
													<TextValidator
															label="Cantidad solicitada por donación en euros (equivalente a una papeleta en el sorteo solidario)"
															onChange={this.handleChange('price')}
															name="price"
															type="number"
															style={inputStyle}
															value={price}
															validators={['required', 'minNumber:1']}
															errorMessages={['Este campo es obligatorio', 'La donación solicitada no puede ser inferior a 1 €']}
													/>
												</Grid>
												<Grid item xs={12}>
													<FormControlLabel
															style={{ marginLeft: '12px' }}
															control={
																	<Checkbox
																	checked={this.state.isPrivate}
																	onChange={this.handleCheckChange}
																	name="isPrivate"
																	style={{color: '#000000'}}
																	/>
															}
															label={<Typography fontFamily={'Quicksand'}>Estas donaciones son privadas y solo para miembros de la asociación:</Typography>}
													/>

													{isPrivate &&
													<>
														<br></br>
														<label style={{ color: 'rgba(0, 0, 0, 1)'}} for="privatePass">Añadir la clave que tienen que poner los miembros de la asociación. Debe tener entre 8 y 12 caracteres.</label>
														<TextValidator
																onChange={this.handleChange('privatePass')}
																name="privatePass"
																style={inputStyle}
																label={'Clave privada'}
																value={privatePass}
																validators={['required', 'minStringLength:8', 'maxStringLength:12']}
																errorMessages={['Este campo es obligatorio', 'La clave debe ser de al menos 8 caracteres', 'La clave debe ser como máximo de 12 caracteres']}
														/>
													</>
													}
												</Grid>
												<Grid item  xs={12} justifyContent={'center'} sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
													<Button color="inherit" disabled={activeStep === 0} onClick={this.handleBack} sx={{ mr: 1 }}>
														Atrás
													</Button>
													<Button type="submit" variant="contained">
														Siguiente
													</Button>
												</Grid>
											</Grid>
										</ValidatorForm>
									</React.Fragment>}
									{activeStep === 2 && <React.Fragment>
										<ValidatorForm
											ref="form3"
											style={{ width: '100%' }}
											onSubmit={this.handleNextStep}
											onError={errors => console.log(errors)}
										>
											<Grid container spacing={2}>
												<Grid item xs={12}>
													<TextValidator
														label="Premio que se sortea"
														onChange={this.handleChange('prize')}
														name="prize"
														value={prize}
														style={inputStyle}
														validators={['required']}
														errorMessages={['Este campo es obligatorio']}
													/>
												</Grid>
												<Grid item xs={12}>
													<Paper >
														<label style={{ color: 'rgba(0, 0, 0, 1)', padding: 5}}>Descripción del premio o recompensa (Opcional)</label>
														<div className="container" style ={{paddingTop: 5}}>
															<MDEditor style={{fontFamily: 'Quicksand'}} height={200} value={prizeDescription} onChange={this.handleMarkdownChange('prizeDescription')} />
														</div>
													</Paper>
												</Grid>
												<Grid item  xs={12} justifyContent={'center'} sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
													<Button color="inherit" disabled={activeStep === 0} onClick={this.handleBack} sx={{ mr: 1 }}>
														Atrás
													</Button>
													<Button type="submit" variant="contained">
														Siguiente
													</Button>
												</Grid>
											</Grid>
										</ValidatorForm>
									</React.Fragment>}
									{activeStep === 3 && <React.Fragment>
										<ValidatorForm
											ref="form4"
											style={{ width: '100%' }}
											onSubmit={this.handleNextStep}
											onError={errors => console.log(errors)}
										>
											<Grid container spacing={2}>
												<Grid item xs={4}>
													<label style={{ color: 'rgba(0, 0, 0, 1)', marginTop: '16px'}} for="startDate">Fecha de comienzo del proceso de donaciones</label>
													<TextValidator
															onChange={this.handleChange('startDate')}
															name="startDate"
															type="date"
															style={inputStyleLabel}
															value={startDate}
															validators={['required', 'isPastDate', 'oneWeekDate']}
															errorMessages={['Este campo es obligatorio', 'La fecha tiene que ser posterior a hoy', 'La fecha tiene que ser de al menos una semana en el futuro']}
													/>
												</Grid>
												<Grid item xs={4}>
													<label style={{ color: 'rgba(0, 0, 0, 1)'}} for="endDate">Fecha de finalización del proceso de donaciones</label>
													<TextValidator
															onChange={this.handleChange('endDate')}
															name="endDate"
															type="date"
															style={inputStyleLabel}
															value={endDate}
															defaultValue="2020-05-24"
															validators={['required', 'isPastDate', 'dateIsSmallerThanStartDate']}
															errorMessages={['Este campo es obligatorio', 'La fecha tiene que ser posterior a hoy', 'La fecha de fin tiene que ser posterior a la de comienzo']}
													/>
												</Grid>
												<Grid item xs={4}>
													<label style={{ color: 'rgba(0, 0, 0, 1)'}} for="drawDate">Fecha de realización del sorteo solidario</label>
													<TextValidator
															onChange={this.handleChange('drawDate')}
															name="drawDate"
															type="date"
															style={inputStyleLabel}
															value={drawDate}
															validators={['required', 'isPastDate', 'dateIsSmallerThanEndDate']}
															errorMessages={['Este campo es obligatorio', 'La fecha tiene que ser posterior a hoy', 'La fecha del proceso de donaciones debe ser posterior a la de finalización del proceso de donaciones']}
													/>
												</Grid>
												<Grid item xs={12}>
													<TextValidator
														label="Nombre del notario que acredita el sorteo"
														onChange={this.handleChange('notaryName')}
														name="notaryName"
														value={notaryName}
														style={inputStyle}
														validators={['required']}
														errorMessages={['Este campo es obligatorio']}
													/>
												</Grid>
												<Grid item xs={12}>
													<Paper >
														<label style={{ color: 'rgba(0, 0, 0, 1)', padding: 5}}>Descripción de la forma de elección del ganador (Opcional)</label>
														<div className="container" style ={{paddingTop: 5}}>
															<MDEditor style={{fontFamily: 'Quicksand'}} height={200} value={winningCondition} onChange={this.handleMarkdownChange('winningCondition')} />
														</div>
													</Paper>
												</Grid>
												<Grid item  xs={12} justifyContent={'center'} sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
													<Button color="inherit" disabled={activeStep === 0} onClick={this.handleBack} sx={{ mr: 1 }}>
														Atrás
													</Button>
													<Button type="submit" variant="contained">
														Siguiente
													</Button>
												</Grid>
											</Grid>
										</ValidatorForm>
									</React.Fragment>}
									{activeStep === 4 && 
										<Grid container spacing={2}>
											<Grid item xs={12} style={{textAlign: 'center', marginTop: '64px', marginBottom: '64px'}}>
												<Typography fontFamily={'Quicksand'} fontWeight={700} fontSize={'24px'} mb={'16px'}>
													¡Enhorabuena! ya tienes todo listo para crear tu proyecto en la app dowin
												</Typography>
												<Typography fontFamily={'Quicksand'} fontWeight={500} fontSize={'16px'}>
													Por favor, antes de finalizar, revisa que se vea todo correctamente en el lado derecho de la pantalla. 
												</Typography>
												<Typography fontFamily={'Quicksand'} fontWeight={500} fontSize={'16px'}>
													Puedes modificar los datos pulsando ATRÁS.
												</Typography>
											</Grid>
											<Grid item xs={12} justifyContent={'center'} sx={{ display: 'flex', flexDirection: 'row', pt: 2, mb: '16px' }}>
												<Button color="inherit" onClick={this.handleBack} style={{marginRight: '32px'}}>
													Atrás
												</Button>
												<Button variant="contained" color="primary" onClick={this.handleSubmit} >Finalizar y Solicitar Donación</Button>
											</Grid>
										</Grid>
									}
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Grid item alignSelf={"center"} xs={3}>
						<Grid container pl={'16px'} justifyContent={"center"} spacing={2}>
							<Grid container style={{backgroundColor: '#FFF', borderRadius: '5px', padding: '16px', overflow: 'auto'}}>
								<Grid item xs={12}>
									<Typography fontFamily={'Quicksand'} pb={'8px'} fontWeight={700} fontSize={16} color={'#000'}>Aquí puedes ver un ejemplo de cómo se verá en la app de dowin</Typography>
								</Grid>
								<Grid item xs={12}>
									<Grid container>
										<Grid item xs={1}/>
										<Grid item xs={11}>
											<PapProjectDetailInApp project={{
												closingDate: this.state.endDate || new Date(),
												drawDate: this.state.drawDate || new Date(),
												pricePerParticipation: this.state.price,
												projectImageUrl: this.state.imgPreviewURL || 'https://firebasestorage.googleapis.com/v0/b/premiumandpremiumdev.appspot.com/o/images%2FdrawsPromoPics%2Fproportions.png?alt=media&token=833c5896-221d-4eb7-9b02-431ada7dd709',
												isPrivate: this.state.isPrivate,
												title: this.state.title || 'Nombre de vuestra campaña',
												orgName: this.state?.orgSnapshot?.get('displayName'),
												orgAvatarUrl: this.state?.orgSnapshot?.get('imgDownloadUrl'),
												description: this.state.description,
												maxParticipations: Math.ceil(parseFloat(this.state.goalAmount) / parseFloat(this.state.price)) || 0,
												goalAmount,
												prize: this.state.prize || 'Premio para el/los ganadores',
												winningCondition: (this.state.winningCondition === MKDWINNER || !this.state.winningCondition) ? `La persona (o varias personas) agraciada será elegida de acuerdo a las bases del sorteo más abajo listadas. Se realizará el sorteo ante el Notario del Ilustre Colegio de Notarios **${this.state.notaryName}** el día **${this.formatDate(this.state.drawDate, true)}**` : this.state.winningCondition,
												prizeDescription: (this.state.prizeDescription === MKDPRIZE || !this.state.prizeDescription) ? `Con la donación que realizas en este proyecto puedes ganar el estupendo premio de ${this.state.prize || 'Premio para el/los ganadores'}. ¡Tu ayuda tiene premio!` : this.state.prizeDescription,
											}} />
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</>
		);
	}
}