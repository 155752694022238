import React from 'react';
import { jsPDF } from "jspdf";

const _MDtoPDF = () => {
    const doc = new jsPDF()
    let first = 20
    const SPACE_LEFT = 20
    const { fontName: FONT } = doc.getFont()
  
    const _addPage = () => {
      doc.addPage()
      first = 20
    }
  
    const _writeH1 = (text) => {
      doc.setFontSize(16)
      doc.text(text, SPACE_LEFT, first)
      first += 10
    }
  
    const _writeH2 = (text) => {
      doc.setFontSize(14)
      doc.text(text, SPACE_LEFT, first)
      first += 7
    }
  
    const _writeBold = (text) => {
      doc.setFont(FONT, 'bold')
      doc.text(text, SPACE_LEFT, first)
      first += 5
      doc.setFont(FONT, 'normal')
    }
  
    const _writeItalic = (text) => {
      doc.setFont(FONT, 'italic')
      doc.text(text, SPACE_LEFT, first)
      first += 5
      doc.setFont(FONT, 'normal')
    }
  
    const _writeText = (text) => {
      doc.setFontSize(12)
      const w = doc.internal.pageSize.getWidth() - (SPACE_LEFT*2);
      const lineIncrement = Math.ceil((text.length * 2.1) / w) * 5;
      doc.text(text, SPACE_LEFT, first, {maxWidth: w, align: 'justify'})
      first += Math.max(5,lineIncrement);
    }
  
    const MDtoPDF = (content) => ({
      parseMarkdown: () => {
        const parts = content.split('\n')
  
        parts.forEach((part) => {
          if (part === '---') {
            _addPage()
          } else if (part.startsWith('# ')) {
            _writeH1(part.replace('# ', ''))
          } else if (part.startsWith('## ')) {
            _writeH2(part.replace('## ', ''))
          } else if (part.startsWith('__') && part.endsWith('__')) {
            _writeBold(part.replace(/__/gm, ''))
          } else if (part.startsWith('_') && part.endsWith('_')) {
            _writeItalic(part.replace(/_/gm, ''))
          } else {
            _writeText(part)
          }
        })
  
        return MDtoPDF(content)
      },
      saveAsPDF: (filename) => {
        doc.save(filename)
      },
      getDoc: () => {
        return doc;
      },
    })
  
    return MDtoPDF
  }

export default _MDtoPDF;