export function LEGAL_BASES_PLACEHOLDER(data){
    const {
        orgName,
        title,
        prize,
        pricePerParticipation,
        maxParticipations,
        location,
        address,
        cif,
        startDate,
        closingDate,
        drawDate,
        winnerSelection,
    } = data;

    return `## BASES LEGALES DEL SORTEO DE ${orgName}

## "CAMPAÑA DE DONACION: ${title}”

## DEFINICIONES
Premio: ${prize} 

Papeletas: Cada papeleta del sorteo corresponderá a una donación de ${pricePerParticipation} euros, a la organización siendo el número máximo de participaciones de ${maxParticipations} equivalente a la cantidad solicitada por la organización para su proyecto solidario y que serán depositadas en la aplicación de Premium&Premium.

Participante: Donante a la organización para la realización de su proyecto solidarios y, consecuentemente, adjudicatario de una o varias Papeletas a través de la aplicación de Premium&Premium que cumpla con los términos y condiciones establecidos en las presentes Bases.

La organización: ${orgName}

Aplicación utilizada: Se utilizara la aplicación de Premium&Premium DoWin.

Sorteo: significa sorteo del premio bajo los términos y condiciones descritos en estas bases.

## PRIMERO. - EMPRESA ORGANIZADORA DEL SORTEO
El presente sorteo se organiza por ${orgName}, domiciliada en ${location}, en la calle ${address} y con N.I.F. ${cif} (en adelante, “la organización”).

${orgName}, como organizadora del Sorteo, se constituye como única garante de la disponibilidad del premio y como única obligada a la entrega del bien al ganador del Sorteo.

## SEGUNDO. - OBJETO DEL SORTEO
El objeto del sorteo (en adelante, el “Sorteo”) es la incentivación de la captación de fondos para la ejecución del proyecto solidario indicado por “la organización”.

## TERCERO. - ÁMBITO GEOGRÁFICO
El ámbito de aplicación de este Sorteo es el territorio nacional del Reino de España.

## CUARTO. - ÁMBITO TEMPORAL
El periodo de vigencia del Sorteo, durante el cual los participantes podrán realizar la acción que da derecho a participar en el mismo, dará comienzo el ${startDate} (inclusive) y finalizará el mismo día ${closingDate} (inclusive) con la elección del ganador en la fecha ${drawDate}.

---
## QUINTO. - COMUNICACIÓN
El Sorteo se anunciará mediante anuncio en la aplicación de Premium & Premium y en la página web de la organización si la tuviera.

## SEXTO. - MECÁNICA
Todas aquellas personas que deseen participar en el Sorteo deberán cumplir con los requisitos establecidos en las presentes Bases. La mecánica del Sorteo será la que se detalla a continuación:

El Sorteo se promocionará, durante el periodo indicado en el Apartado Cuarto. Para participar en el Sorteo, todos los participantes deberán rellenar un formulario online de la aplicación Premium&Premium en el que deberán indicar una serie de datos personales tales como: nombre, apellidos, teléfono y mail. Por el mero hecho de cumplir los requisitos planteados, los participantes entrarán en el sorteo de del premio solicitado al realizar una donación a “la organización” para la realización de su proyecto solidario. El ganador, saldrá de todas las personas que hayan completado correctamente el formulario con los datos indicados y hayan realizado una donación a “la organización”. Las bases legales estarán disponibles en todo momento durante la vigencia del Sorteo para todos los participantes en la aplicación Premium&Premium. La correspondiente cumplimentación del formulario mencionado con los datos requeridos conforme a lo establecido en estas Bases implica la aceptación de las bases en su totalidad.
En consideración con lo anterior, solamente podrán participar quienes cumplan con todas las condiciones descritas durante todo el periodo de vigencia del Sorteo. En el caso de que se detectara la utilización de medios fraudulentos que contravengan la transparencia del Sorteo e incluso ante la posible sospecha de una participación fraudulenta por parte de cualquier usuario, los Organizadores se reservan la facultad de corroborar por cualquier medio (incluido la solicitud de la documentación al propio usuario) que los participantes cumplen con los requisitos y las condiciones señaladas en las presentes bases, reservándose el derecho a dar de baja al mencionado participante, así como a excluir automáticamente y sin previo aviso a todo aquel participante que por parte de los Organizadores se estime que no está cumpliendo con las condiciones previstas en las presentes bases. Del mismo modo P&P Advertising Marketplace, gestora de la aplicación Premium&Premium podrá paralizar la realización de donaciones y el consecuente sorteo en caso de que detecte alguna irregularidad que comunicará inmediatamente a “la organización”. En este sentido, se rechazará cualquier participación que no cumpla con las condiciones de participación establecidas en las presentes Bases.
La participación en el Sorteo es gratuita. Los participantes obtendrán sus boletos para participar en el sorteo al realizar una donación desinteresada a “la organización” para la realización de su proyecto solidario. Para obtener el Premio no es necesario realizar ninguna compra adicional. Sin limitar en ninguna medida los derechos reconocidos a los consumidores por las disposiciones que resulten aplicables, ni “la organización” ni Premium&Premium son responsables de la pérdida o demora por cualquier interrupción, falta temporal de disponibilidad o de continuidad de funcionamiento, problema en la transmisión, perdida de información, fraude, desperfecto en la red, problema de funcionamiento de software, fallo de acceso, de comunicación o de respuesta o alteración del Sorteo por problemas técnicos o de otra naturaleza que escapen a su control. “La organización” es la única responsable de realizar y organizar el sorteo, así como de entregar el premio al o los ganadores del sorteo. Premium&Premium es simplemente una aplicación que permite la intermediación de “la organización” con los donantes y no es responsable ni directa ni indirectamente de la organización del sorteo y/o la entrega del premio.

---
## SÉPTIMO. - CONDICIONES DE PARTICIPACIÓN
La participación en el Sorteo es personal e intransferible, podrán participar en este Sorteo las personas físicas que sean mayores de dieciocho años (+18).
La identidad del ganador se podrá comprobar mediante la presentación del D.N.I. u otro documento oficial acreditativo de la identidad de las personas físicas.
No se aceptará ninguna participación que resulte contraria al orden público, a la normativa, que infrinjan derechos de terceros o que resulten ofensivos o perjudiciales para cualquier tercero.
“La organización” queda facultada para excluir a los participantes que no cumplan con los requisitos del presente Sorteo o que, a juicio de “la organización”, hubieran incumplido cualquier otra previsión de las presentes Bases.
Del mismo modo, cualquier indicación falsa o indicios de identidad falsa ocasionará la descalificación inmediata del participante y, en su caso, la imposibilidad de disfrutar del Premio.
Igualmente, “la organización” podrá eliminar del Sorteo a aquellos participantes cuyas respuestas resultasen ser manifiestamente erróneas en relación con la naturaleza del Sorteo, por no ser coherentes con lo planteado y carezcan de cualquier tipo de relación con el objeto del reto.
El participante deberá dar respuesta a ${orgName} aceptando el premio mediante correo electrónico en un plazo máximo de tres (3) meses desde la comunicación en la que se le notifique que es ganador. En caso contrario, el premio se declarará desierto. A efectos aclaratorios, se entenderá que se han realizado las gestiones oportunas para la localización del Participante ganador del Sorteo, si ha transcurrido tres (3) meses desde que ${orgName} haya contactado, con el mismo y éste no se hubiera dirigido a ésta por ninguno de los medios que a tal efecto se le hubieran facilitado.
“La organización” se reserva el derecho a efectuar en cualquier momento los cambios que entienda oportunos en las presentes Bases y a tomar las medidas convenientes para resolver conflictos o dificultades que puedan surgir en el Sorteo, siempre que estén justificadas, no perjudiquen indebidamente a los participantes en el Sorteo y se comuniquen debidamente.
El Participante que resulte ganador del sorteo del Premio y acepte el mismo deberá llevar a cabo con ${orgName} todos los trámites necesarios para la formalización de la transmisión de la propiedad del premio. A estos efectos, el ganador se compromete a desplazarse a la Notaria que le sea indicada por ${orgName} para la realización de dichos trámites en la fecha que éste determine. La totalidad de los trámites deberán ser realizados en un plazo máximo de tres (3) meses desde que se comunique al Participante su condición de ganador. En el supuesto de que no se cumpla el mencionado plazo por causas imputables al ganador, éste perderá el derecho al premio sin derecho a reclamar frente a ${orgName} por ningún concepto.
---
${orgName} ha recaudado los fondos con fines solidarios, sociales o educativos y como incentivación a sus donantes realiza este sorteo. Los fondos recaudados en esta campaña son para los fines propuestos, por lo tanto, los gastos e impuestos derivados de la entrega del premio serán satisfechos por el ganador del Sorteo. En este sentido, el Participante debe tener en cuenta que el premio está sujeto a tributación e ingreso a cuenta conforme a lo dispuesto en la normativa vigente del Impuesto sobre la Renta de las Personas Físicas. Por las mismas razones el ganador del sorteo asumirá el importe equivalente al 100% del Impuesto sobre Transmisiones Patrimoniales si las hubiera, la tributación adicional generada para el ganador en su Impuesto sobre la Renta de las Personas Físicas que deba satisfacer el Participante conforme a la normativa tributaria aplicable, así como la totalidad de los importes que deban ser satisfechos al Registro de la Propiedad, a la Notaría y a la gestoría que se deriven directamente de la transmisión de la propiedad del premio en caso de que fuese necesario.
Sin limitar en ninguna medida los derechos reconocidos a los consumidores por las disposiciones que resulten aplicables, ni ${orgName} ni Premium&Premium son responsables de la pérdida o demora por cualquier interrupción, falta temporal de disponibilidad o de continuidad de funcionamiento, problema en la transmisión, pérdida de información, fraude, desperfecto en la red, problema de funcionamiento del Sitio Web y de la aplicación, fallo de acceso, de comunicación o de respuesta o alteración por problemas técnicos o de otra naturaleza que escapen a su control.

## OCTAVO. - PREMIO
El participante que, de acuerdo con estas Bases, resulte ganador del Sorteo, obtendrá ${prize}. El Premio no incluirá ninguna otra prestación, gasto o servicio diferente de los expresamente indicados en el presente apartado. En consecuencia, correrá a cargo del ganador cualquier prestación o servicio que no se haya indicado expresamente en la descripción anterior.
En el supuesto de que estas bases regulen un determinado periodo durante el cual se pueda disfrutar del Premio, éste deberá ser respetado por el ganador sin que quepa posibilidad de modificarlo.
Si debido a circunstancias más allá del control de “la organización”, es incapaz de proporcionar el Premio indicado podrá conceder otro premio equivalente de igual o mayor valor.
Cuando el Premio esté sujeto a garantía del fabricante o del proveedor, en caso de fallo o mal funcionamiento del artículo, el ganador deberá dirigirse al servicio técnico del proveedor o fabricante del mismo.
El Premio objeto del presente Sorteo queda sujeto a estas Bases. Si el ganador rechaza el Premio, no se le ofrecerá ningún premio alternativo. El Premio no podrá ser objeto de negocios jurídicos celebrados con fines comerciales o publicitarios.

---
## NOVENO. - SELECCIÓN DEL GANADOR Y ENTREGA DEL PREMIO

${winnerSelection}

## DÉCIMO. - DERECHOS DE IMAGEN
El participante ganador autoriza a “la organización” y a Premium&Premium a utilizar su nombre e imagen en el material publicitario relacionado con el presente Sorteo, incluyendo, sin limitación, a través de internet o para comunicar su condición de ganador, así como para la promoción de sus productos y actividades, sin limitación temporal ni territorial. En todo caso, el ganador está de acuerdo en participar sin contraprestación en la publicidad relacionada con este Sorteo.
Todo ello con la única salvedad y limitación de aquellas utilizaciones o aplicaciones que pudieran atentar al derecho al honor en los términos previstos en la Ley Orgánica 1/1982, de 5 de mayo, de Protección Civil al Derecho al Honor, la Intimidad Personal y familiar y a la Propia Imagen.

---
## DÉCIMOPRIMERO. - ASISTENCIA AL USUARIO
En caso de producirse cualquier incidencia en el Sorteo, el participante puede dirigirse a “La organización”, a través de correo electrónico, indicando en la referencia el nombre del sorteo. En caso de que haya algún problema técnico con la aplicación, podrán ponerse en contacto con Premium&Premium a través del formulario disponible en la página web.

## DÉCIMOSEGUNDO. - PROTECCIÓN DE DATOS
En cumplimiento de lo previsto en la normativa aplicable, le informamos que los datos del participante serán utilizados con el único objeto de gestionar su participación en el Sorteo incluyendo, en su caso, la publicación de la condición de participante ganador. El tratamiento de datos personales será llevado a cabo de conformidad con el Reglamento General de Protección de Datos del Consejo y de la Comisión de la Unión Europea, 679/2016 (RGPD) y en la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales, y demás normativa aplicable. Asimismo, se informa a los participantes en la presente promoción de los siguientes aspectos sobre privacidad y protección de datos.
_¿Quién es el responsable del tratamiento de sus datos personales?_
El responsable del tratamiento es la entidad P&P Adverting Marketplace, con NIF B74159898. En adelante, LA ENTIDAD.

_¿Cómo obtenemos sus datos personales?_
Para dar respuesta a esta pregunta se debe distinguir entre:
Fuentes de las que proceden los datos personales:
Aportados por el participante en la relación que ha entablado con LA ENTIDAD, al participar en la presente promoción.
Derivados de la propia gestión, mantenimiento y desarrollo de la relación entablada.
Tipologías de datos personales tratados por LA ENTIDAD:
Datos identificativos.

---
_¿Con qué finalidades tratamos sus datos personales?_
En LA ENTIDAD tratamos sus datos personales para alcanzar las finalidades que se detallan a continuación:
Gestionar el Sorteo y/o su participación en el mismo, así como proporcionarle información para que su participación en el Sorteo se ajuste a lo establecido en las Bases Legales reguladoras del mismo.
Verificar la inexistencia de actuaciones o participaciones fraudulentas o contrarias a las Bases Legales reguladoras del presente Sorteo.
Gestionar la entrega y/o facilitar el acceso y disfrute del premio otorgado al ganador.
Dar cumplimiento a las obligaciones legales y/o fiscales que pudieran corresponder a “la organización” como organizadora del presente Sorteo.
Adicionalmente, y exclusivamente en relación con los participantes que resulten ganadores de la presente promoción, sus datos personales y, en concreto, su nombre e imagen, serán tratados con la finalidad de divulgar y comunicar su condición de ganadores en el Sorteo y difundidos con tal fin a través de los canales de comunicación corporativa (interna o externa) gestionados por LA ENTIDAD. Se informa de que tal difusión podrá ser llevada a cabo mediante canales digitales o analógicos, con efectos de difusión o promoción corporativa.
Mediante la aceptación de las presentes Bases Legales, el participante acepta la finalidad de tratamiento de datos descrita, en caso de resultar ganador de la presente promoción, y autoriza a LA ENTIDAD para llevar a cabo una utilización de la imagen a través de la cual participa en el Sorteo, con carácter no exclusivo, universal (sin limitación geográfica alguna), por el máximo tiempo permitido por la legislación vigente y de manera gratuita. En todo caso, tales publicaciones serán realizadas cumpliendo las normativas que les resulten de aplicación.
Por último, LA ENTIDAD le informa de que el tratamiento de datos con la finalidad de difusión y comunicación corporativa descrita podría suponer la comunicación de datos a cualquier tercero con acceso a los medios y canales de comunicación donde se efectúen las citadas publicaciones, así como que cuando tales publicaciones sean efectuadas a través de medios accesibles sin limitación geográfica, como por ejemplo Internet, el tratamiento de datos permitirá que terceros situados fuera del territorio del Espacio Económico Europeo puedan acceder a los datos personales publicados.
_¿Cuál es la legitimación de LA ENTIDAD para el tratamiento de sus datos?_
La legitimación para el tratamiento de sus datos se basa en su consentimiento.
Mediante su inscripción como participante en el Sorteo y con la aceptación de las presentes Bases, Vd. consiente el tratamiento de sus datos, en relación con las finalidades que posibilitan gestionar su participación en el Sorteo, incluido el tratamiento de datos personales de los ganadores de la promoción con finalidades de difusión y comunicación corporativa de LA ENTIDAD.
Por último, le informamos de que LA ENTIDAD tratará también sus datos para el cumplimiento de obligaciones legales que le correspondan como organizadora de la promoción.

---
_¿Qué datos personales debe facilitarnos?_
Para participar en el Sorteo, debe facilitar los datos requeridos en el formulario de inscripción.
Si en la condición de ganadores en la presente promoción implica la cumplimentación por su parte de sus datos personales mediante un formulario en el que determinados datos se indican como obligatorios, será preciso que proporcione al menos los datos marcados como obligatorios para poder gestionar la entrega del correspondiente premio. En caso de que no proporcione al menos tales datos, no podrá tramitarse dicha entrega.
_¿Qué debe garantizarnos al aportar sus datos de carácter personal?_
Al aportar sus datos, debe garantizarnos que los mismos son verdaderos, exactos, completos y se encuentran actualizados, siendo responsable de cualquier daño o perjuicio, directo o indirecto, que pudiera ocasionarse como consecuencia del incumplimiento de tal obligación.

_¿A qué destinatarios se comunicarán sus datos personales?_
Los datos personales tratados por LA ENTIDAD para alcanzar las finalidades detalladas anteriormente podrán ser comunicados a los siguientes destinatarios en función de la base legitimadora de la comunicación:
Organismos y Administraciones Públicas.
“La organización”
Cualquier otra entidad publica o privada que tenga la potestad de obtener esos datos por mandato judicial.

En virtud de lo anterior, las citadas comunicaciones de datos persiguen garantizar el correcto desarrollo de la relación entablada entre EL INTERESADO y LA ENTIDAD, así como dar cumplimiento a obligaciones legales que exigen realizar las mencionadas comunicaciones.

_¿Por cuánto tiempo conservamos sus datos?_
Los datos personales se conservarán durante la vigencia de la relación entablada entre el participante y LA ENTIDAD y, posteriormente, siempre que no haya ejercitado su derecho de supresión, serán conservados teniendo en cuenta los plazos legales que resulten de aplicación en cada caso concreto, teniendo en cuenta la tipología de datos, así como la finalidad del tratamiento.

_¿Cómo protegemos sus datos personales?_
En respuesta a nuestra preocupación por garantizar la seguridad y confidencialidad de sus datos y/o de los datos de su hijo/a, hemos adoptado los niveles de seguridad requeridos para la protección de los datos personales y se han instalado los medios técnicos a su alcance para evitar la pérdida, mal uso, alteración, acceso no autorizado y robo de los datos personales facilitados.

---
_¿Cuáles son sus derechos en relación con el tratamiento que hacemos de sus datos?_
LA ENTIDAD le informa que tiene derecho a acceder a sus datos personales, así como a los de su hijo/a, y obtener confirmación sobre cómo se están tratando dichos datos.
Asimismo, tiene derecho a solicitar la rectificación de los datos inexactos o, en su caso, solicitar su supresión cuando, entre otros motivos, los datos ya no sean necesarios para los fines que hayan sido recabados por LA ENTIDAD.
En determinadas circunstancias, podrá solicitar la limitación del tratamiento de sus datos, en cuyo caso LA ENTIDAD únicamente los conservará para el ejercicio o la defensa de las posibles reclamaciones.
Asimismo, también en determinadas circunstancias, usted podrá oponerse al tratamiento de sus datos personales con la finalidad informada por LA ENTIDAD. En ese caso, LA ENTIDAD cesará en el tratamiento de los datos personales, salvo que concurran motivos legítimos, o para garantizar el ejercicio o la defensa de posibles reclamaciones.
Por último, usted podrá solicitar el derecho a la portabilidad y obtener para sí mismo o para otro prestador de servicios determinada información derivada de la relación formalizada con LA ENTIDAD
Le recordamos además que en cualquier momento podrá oponerse al tratamiento de sus datos con finalidades publicitarias o promocionales.
Tales derechos podrán ser ejercidos a través de una solicitud por correo electrónico o mediente el formulario disponible en la web de Premium&Premium.
En ambos supuestos, se exige acreditar la identidad de la persona que ejerce sus derechos, mediante el envío de copia de su DNI, NIE, Pasaporte o documento equivalente, por las dos caras.
LA ENTIDAD le facilitará la información solicitada en el plazo máximo de un mes a partir de la recepción de la solicitud. Dicho plazo podrá prorrogarse otros dos meses en caso necesario, teniendo en cuenta la complejidad y el número de solicitudes.
Podrá retirar el consentimiento en cualquier momento, en el caso de que se haya otorgado el consentimiento para alguna finalidad específica, sin que ello afecte a la licitud del tratamiento basado en el consentimiento previo a su retirada.
Podrá presentar reclamación ante la Autoridad de Control competente en materia de protección de datos. No obstante, en primera instancia, podrá presentar reclamación ante el Delegado de Protección de Datos, quien resolverá la reclamación en el plazo máximo de dos meses.

---
## DÉCIMOTERCERO. - JURISDICCIÓN COMPETENTE:
Para cuantas dudas puedan derivarse de la interpretación del presente documento, las partes se someten a la jurisdicción y fuero de los Juzgados y Tribunales de la Ciudad de Madrid, con renuncia expresa a cualquier otro fuero que pudiera corresponderles.

## DÉCIMOCUARTO. - CONSIDERACIONES GENERALES:
La empresa organizadora se reserva el derecho de acortar, prorrogar, modificar o cancelar este Sorteo si concurrieran circunstancias excepcionales que impidieran su realización, comunicando dichas circunstancias de manera que se evite cualquier perjuicio para los participantes. 
La empresa organizadora del Sorteo no será responsable de los retrasos, pérdidas o deterioros por causas que no le sean imputables. La empresa organizadora tampoco responderá de los casos de fuerza mayor que pudieran impedir a los ganadores el disfrute total o parcial de su premio.
Asimismo, la empresa organizadora quedará exenta de toda responsabilidad si concurriere alguno de los casos señalados, así como de cualquier responsabilidad por los daños y perjuicios que pudiesen ocasionarse durante el disfrute del premio.
La empresa organizadora excluye cualquier responsabilidad por daños y perjuicios de toda naturaleza que puedan deberse a la falta temporal de disponibilidad o de continuidad del funcionamiento de la Web o de las RRSS mediante las cuales se participa en el Sorteo, a la defraudación de la utilidad que los usuarios hubieren podido atribuir al mismo y, en particular, aunque no de modo exclusivo, a los fallos en el acceso a las distintas páginas y envíos de respuestas de participación a través de Internet.
La empresa organizadora se reserva el derecho de eliminar la promoción del Sorteo por causa justificada a cualquier usuario que defraude, altere o inutilice el buen funcionamiento y el transcurso normal y reglamentario de la misma.

## DÉCIMOQUINTO. - EXONERACIÓN
Este Sorteo no es patrocinado, avalado ni administrado por ninguna red social, ni está en modo alguno asociado a ninguna plataforma.

`;
};

export function NOTARY_PARAGRAPH(data) {
    const {
        notaryName
    } = data;

    return `_SORTEO ANTE NOTARIO_
Se realizará el sorteo del premio ante notario. El sorteo ante notario garantiza la transparencia del resultado del Sorteo y como prueba de ello emite un certificado de validez, cuyo resultado es inalterable por el organizador del Sorteo.
“La organización” anunciará el ganador del Sorteo el día indicado en el apartado cuarto de estas bases y solicitará la aceptación expresa de su condición de ganador del premio, y a su vez alguna prueba que acredite su identidad tal como una copia de su D.N.I, asimismo, se le pedirá la dirección de su domicilio y su teléfono.
Si “la organización” no consigue contactar con la persona premiada, o la persona premiada no se pone en contacto con “la organización” en el plazo de 30 días desde la finalización del Sorteo, se nombrará como ganador al primer reserva, y así sucesivamente hasta contactar con el ganador, siempre que el sorteo contemple la posibilidad de que haya reservas.
No obstante, si el participante ganador no aceptara el Premio o si hubiera incumplido las presentes Bases o si renunciara al Premio sin designar otra persona en su lugar, se asignará el premio al primer reserva, y así sucesivamente hasta agotar la lista de reservas.
Si el ganador y ninguno de las reservas fueran localizados conforme a lo anterior, si no aceptan el Premio o si no tienen derecho al Premio, “la organización” se reserva el derecho a declarar el Premio desierto.
Las bases de este Sorteo están depositadas en el Notario del Ilustre Colegio de Notarios "${notaryName}" y estarán disponibles en el Sitio Web de la organización si la tuviese y en la aplicación de Premium&Premium.
`;
};

export function ORGANIZATION_PARAGRAPH() {
    return `_SORTEO REALIZADO POR LA ORGANIZACION_
Se realizará el sorteo del premio por parte de la organización. El sorteo realizado por la organización debe garantizar la transparencia del resultado del Sorteo y como prueba de ello debe ser un acto público o retrasmitido por redes sociales en directo.
“La organización” anunciará el ganador del Sorteo el día indicado en el apartado cuarto de estas bases y solicitará la aceptación expresa de su condición de ganador del premio, y a su vez alguna prueba que acredite su identidad tal como una copia de su D.N.I, asimismo, se le pedirá la dirección de su domicilio y su teléfono.
Si “la organización” no consigue contactar con la persona premiada, o la persona premiada no se pone en contacto con “la organización” en el plazo de 30 días desde la finalización del Sorteo, se nombrará como ganador al primer reserva, y así sucesivamente hasta contactar con el ganador, siempre que el sorteo contemple la posibilidad de que haya reservas.
No obstante, si el participante ganador no aceptara el Premio o si hubiera incumplido las presentes Bases o si renunciara al Premio sin designar otra persona en su lugar, se asignará el premio al primer reserva, y así sucesivamente hasta agotar la lista de reservas.
Si el ganador y ninguno de las reservas fueran localizados conforme a lo anterior, si no aceptan el Premio o si no tienen derecho al Premio, “la organización” se reserva el derecho a declarar el Premio desierto.
`;
};

export function LOTTERY_PARAGRAPH() {
    return `_SORTEO LIGADO A UN SORTEO DE LOTERIA O SIMILAR_
El premio se otorgará si el número premiado en la lotería nacional, organización nacional de ciegos, cruz roja o sorteo de ámbito nacional similar asociado al sorteo, coincide con el número asignado al donante. “La organización” anunciará el ganador del Sorteo el día indicado en el apartado cuarto de estas bases y solicitará la aceptación expresa de su condición de ganadores del premio, y a su vez alguna prueba que acredite su identidad tal como una copia de su D.N.I, asimismo, se le pedirá la dirección de su domicilio y su teléfono.
Si “la organización” no consigue contactar con la persona premiada, o la persona premiada no se pone en contacto con “la organización” en el plazo de 30 días desde la finalización del Sorteo, se entenderá que la expresa renuncia de la persona premiada a recibir el premio que repercutirá en beneficio de la organización.
`;
};

export default { LEGAL_BASES_PLACEHOLDER, NOTARY_PARAGRAPH, ORGANIZATION_PARAGRAPH, LOTTERY_PARAGRAPH };