import React, { useEffect, useState } from 'react'
import {
   Button,
   Divider,
   Grid,
   IconButton,
   Paper,
   Typography,
} from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import moment from 'moment';
import ReactMarkdown from 'react-markdown'

function _getMonthInSpanish(month) {
	const monthsMap = {
		'1': 'Enero',
		'2': 'Febrero',
		'3': 'Marzo',
		'4': 'Abril',
		'5': 'Mayo',
		'6': 'Junio',
		'7': 'Julio',
		'8': 'Agosto',
		'9': 'Septiembre',
		'10': 'Octubre',
		'11': 'Noviembre',
		'12': 'Diciembre'
	};

	return monthsMap[month];
}

function esFormat(stringDate) {
	const splitDate = stringDate.split('-');

	if (splitDate.length > 3) {
		return `${splitDate[0]} de ${_getMonthInSpanish(splitDate[1])} de ${splitDate[2]} ${splitDate[3]}:${splitDate[4]}`;
	}
	return `${splitDate[0]} de ${_getMonthInSpanish(splitDate[1])} de ${splitDate[2]}`;
}

function moneyFormat(number, decPlaces, decSep, thouSep) {
   decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces,
   decSep = typeof decSep === "undefined" ? "," : decSep;
   thouSep = typeof thouSep === "undefined" ? "." : thouSep;
   var sign = number < 0 ? "-" : "";
   var i = String(parseInt(number = Math.abs(Number(number) || 0).toFixed(decPlaces)));
   var j = (j = i.length) > 3 ? j % 3 : 0;

   return (sign +
       (j ? i.substr(0, j) + thouSep : "") +
       i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
       (decPlaces ? decSep + Math.abs(number - i).toFixed(decPlaces).slice(2) : "")).trim();
}

function PapProjectDetailInApp(props) {
  const project = props.project;

  project.humanEndDate = esFormat(moment(project.closingDate).format('D-M-yyyy'));
  project.humanDrawDate = esFormat(moment(project.drawDate).format('D-M-yyyy'));
  project.pricePerParticipation = Number.parseFloat(project.pricePerParticipation) || 0;

  return (
    <Paper style={{maxWidth: '300px', overflowY: 'auto'}} elevation={5}>
      <Grid container style={styles.container}>
         <Grid item xs={12} style={{maxWidth: 'inherit',}}>
            <div style={{position: 'relative', maxWidth: 'inherit',}}>
               <img
                  alt={"campaign image"}
                  style={styles.image}
                  src={project.projectImageUrl}
               />
               {project.isPrivate && 
                  <div style={styles.privateTag}>
                     <Typography style={styles.privateTagTypography} >Privado</Typography>
                  </div>
               }         
               <div style={styles.shareButton}>
                  <IconButton aria-label="instagram" size="large">
                     <ShareIcon fontSize={"30"} style={{fill: '#3239E5'}}/>
                  </IconButton>
               </div>
            </div>
         </Grid>
         <Grid item xs={12} style={styles.header}>
            <div style={styles.avatar}>
              <img
                alt={"organization image"}
                style={styles.avatar}
                src={project.orgAvatarUrl}
              />
            </div>
            <div style={{maxWidth: 'inherit',}}>
                <Typography style={styles.headerTitle}>{project.title}</Typography>
                <Typography style={styles.headerOrgName}>{project.orgName}</Typography>
            </div>
         </Grid>
         <Grid item xs={12} style={styles.content}>
            {/* DESCRIPCION */}
            <Typography style={styles.sectionTitle}>Descripcion de la campaña</Typography>
            <ReactMarkdown style={styles.sectionTypography} >{project.description}</ReactMarkdown>

            {/* AYUDA DONANDO DESDE */}
            <Typography style={styles.sectionTitle}>Ayuda donando desde: </Typography>
            <Grid container spacing={2}>
                <Grid item xs={6} alignSelf={"center"}>
                    <Typography style={styles.moneyTypography}>{project.pricePerParticipation}€</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Button style={styles.blackButton} variant="contained" startIcon={<VolunteerActivismIcon style={{fill: '#FFF'}}/>}>DONAR</Button>
                </Grid>
            </Grid>
            {/* EN TOTAL SE NECESITAN */}
            <Typography style={styles.sectionTitle}>En total se necesitan: </Typography>
            <Typography style={styles.moneyTypography}>{moneyFormat(project.goalAmount)} €</Typography>
            {/* PORCENTAJE DE FINANCIACION */}
            <Typography style={styles.sectionTitle}>Porcentaje de financiacion al momento: </Typography>
            <div key={5} style={styles.progressBarContainer}>
            </div>
            {/* DONANDO PODRÍAS GANAR */}
            <Typography style={styles.sectionTitle}>Donando podrías ganar: </Typography>
            <Typography style={styles.prizeTypography}>{project.prize} </Typography>
			<ReactMarkdown style={styles.sectionTypography} >{project.prizeDescription}</ReactMarkdown>

            {/* FECHAS Y CONDICIONES DE LA CAMPAÑA */}
            <Typography style={styles.sectionTitle}>Fechas y condiciones de la campaña: </Typography>
            <div style={styles.footerTypographyGroup}>
                <Typography style={styles.footerTypography} >{"Finaliza: " + project.humanEndDate }</Typography>
                <Typography style={styles.footerTypography} >{"Ganador solidario: " + project.humanDrawDate }</Typography>
                <Typography style={styles.footerTypography} >{"Número total de participaciones: " + project.maxParticipations}</Typography>
                <Typography style={styles.footerTypography}>{" "}</Typography>
            </div>

            {/* Bases legales */}
            <Divider style={{marginBottom: 5}}/>
			<ReactMarkdown style={styles.sectionTypography} >{project.winningCondition}</ReactMarkdown>
            <Typography style={styles.label}>Puedes consultar las bases legales de este proyecto <Typography style={styles.footerLinkBlue} display={"inline"}>AQUÍ</Typography></Typography>
        </Grid>
      </Grid>
    </Paper>
  );
}

const styles = {
  container: {
    backgroundColor: "#FFFFFF",
    maxWidth: 'inherit',
    maxHeight: '600px',
    overflow: 'auto',
  },
  content: {
    padding: 16,
	maxWidth: 'inherit',
  },
  image: {
    width: '100%',
    height: 167,
    backgroundColor: '#F1F1F1',
  },
  privateTag: {
      position: 'absolute',
      backgroundColor: '#FFFFFF',
      padding: '8px',
      textAlign: 'right',
      right: 0,
      top: 0,
  },
  privateTagTypography: {
    width: 'auto',
    color: "rgba(0, 0, 0, 0.87);",
    fontFamily: "Quicksand",
    fontStyle: "normal",
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: 0.15,
  },
  shareButton: {
   position: "absolute",
    width: 56,
    height: 56,
    backgroundColor: '#FFFFFF',
    alignItems: 'center',
    justifyContent: 'center',
    top: 167-56,  // Image height - Icon height
    right: 0,
  },
  header: {
      display: 'flex',
      paddingTop: '8px',
      paddingLeft: '16px',
	  maxWidth: 'inherit',
      minHeight: '75px',
      alignItems: "flex-start",
  },
  avatar: {
    width: '60px', 
    height: '60px',
    borderRadius: '50px',
    marginRight: '16px',
    backgroundColor: 'transparent',
  },
  title: {
    width: 'auto',
    marginLeft: 72,
    color: "#000000",
    fontFamily: "Quicksand_700Bold",
    fontStyle: "normal",
    fontSize: '20px',
    lineHeight: '20px',
    letterSpacing: 0.15,
    marginTop: 8,
    minHeight: 20,
  },
  orgName: {
    marginLeft: 72,
    width: 'auto',
    color: "rgba(0, 0, 0, 0.87);",
    fontFamily: "Quicksand_400Regular",
    fontStyle: "normal",
    fontSize: '12px',
    lineHeight: '20px',
    letterSpacing: 0.15,
    height: 20,
  },
  sectionTitle: {
    width: 'auto',
    color: "#767676",
    fontFamily: "Quicksand",
    fontWeight: 700,
    fontStyle: "normal",
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: 0.25,
    paddingTop: 10,
    paddingBottom: 10,
  },
  sectionTypography: {
    width: '100%',
    color: "#000000",
    fontFamily: "Quicksand",
    fontStyle: "normal",
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: 0.25,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  moneyTypography: {
    color: "#000000",
    fontFamily: "Quicksand",
    fontWeight: 700,
    fontStyle: "normal",
    fontSize: '18px',
    lineHeight: '20px',
    letterSpacing: 0.25,
  },
  progressBarContainer:{
    background: 'linear-gradient(90deg, #2C90CB 50%, #E1E1E1 50%)',
    width: '90%',
    borderRadius: '5px',
    minHeight: '20px'
  },
  prizeTypography: {
    width: 'auto',
    color: "#000000",
    fontFamily: "Quicksand",
    fontWeight: 700,
    fontStyle: "normal",
    fontSize: '21px',
    lineHeight: '20px',
    letterSpacing: 0.25,
    paddingTop: 5,
    paddingBottom: 5,
    //textTransform: 'capitalize',
  },
  donationButton: {
    marginTop: '8px',
    width: 120,
    backgroundColor: '#3239E5',
    borderRadius: '50px',
    color: '#FFFFFF',
    textAlign: 'center',
    alignItems: 'center',
  },

  footerTypographyGroup: {
    marginRight: 16,
    alignItems: "flex-start",
  },
  footerTypography: {
    width: 'auto',
    color: "rgba(0, 0, 0, 0.87);",
    fontFamily: "Quicksand",
    fontStyle: "normal",
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: 0.15,
    height: '20px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  footerLinkBlue: {
    color: "#2c90cb",
    fontFamily: "Quicksand",
    fontSize: '12px',
    fontWeight: 700,
  },
  label: {
    flex: 1,
    marginRight: 5,
    flexWrap: "wrap",
    color: "rgba(0, 0, 0, 0.87);",
    fontFamily: "Quicksand",
    fontStyle: "normal",
    fontSize: '12px',
    lineHeight: '20px',
    letterSpacing: 0.15,
  },

  headerTitle: {
   marginLeft:  0,
   width: 'auto',
   color: "#000000",
   fontFamily: "Quicksand",
   fontWeight: 700,
   fontStyle: "normal",
   fontSize: '20px',
   lineHeight: '20px',
   letterSpacing: 0.15,
   minHeight: '20px',
   alignItems: 'flex-start',
   wordWrap: 'break-word'
 },
 headerOrgName: {
   marginLeft: 0,
   width: 'auto',
   color: "rgba(0, 0, 0, 0.87);",
   fontFamily: "Quicksand",
   fontStyle: "normal",
   fontSize: '12px',
   lineHeight: '20px',
   letterSpacing: 0.25,
   height: '20px',
 },
 blackButton: {
	fontFamily: 'Quicksand',
	fontSize: '14px',
	lineHeight: '20px',
	fontWeight: "500",
	backgroundColor: '#3239E5',
	width: '120px',
	borderRadius: '50px',
    color: '#FFFFFFF',
},
};

export default PapProjectDetailInApp;